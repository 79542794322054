import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";

export default function Navbar() {
    return (
        <header className="bg-gray-800 md:sticky top-0 z-10">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a className="title-font font-medium text-white mb-4 md:mb-0">
                    <a href="#intro" className="ml-3 text-xl">
                        Nikhil Bharath Ramaswamy
                    </a>
                </a>
                <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center">
                    {/*<a href="#intro" className="mr-5 hover:text-white">*/}
                    {/*    Intro*/}
                    {/*</a>*/}
                    <a href="#about" className="mr-5 hover:text-white">
                        About
                    </a>
                    <a href="#skills" className="mr-5 hover:text-white">
                        Skills
                    </a>
                    <a href="#experience" className="mr-5 hover:text-white">
                        Experience
                    </a>
                    <a href="#projects" className="mr-5 hover:text-white">
                        Projects
                    </a>
                    <a href="#certification" className="mr-5 hover:text-white">
                        Certifications
                    </a>
                    <a href="#leadership" className="mr-5 hover:text-white">
                        Leadership
                    </a>


                   {/* <a href="#testimonials" className="mr-5 hover:text-white">
                        Testimonials
                    </a>*/}
                </nav><a href="./Resume.pdf" download="Resume">
                <button
                    className="inline-flex items-center bg-gray-800 border-0 hover:text-white border border-green-500 py-1 px-6 focus:outline-none hover:bg-green-500 rounded text-base">
                    Resume
                </button></a>
                <a
                    href="#contact"
                    className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0">
                    Contact Him
                    <ArrowRightIcon className="w-4 h-4 ml-1" />
                </a>
            </div>
        </header>
        
    );
}