import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";
import { skill } from "../data";

export default function Skills() {
    return (
        <section id="skills">
            <div className="container px-5 py-10 mx-auto">
                <div className="text-center mb-20">
                    <ChipIcon className="w-10 inline-block mb-4" />
                    <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                        Skills &amp; Technologies
                    </h1>
                    <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                        He is skilled with
                    </p>
                </div>
                <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    {skill.map((skill) => (
                        /*<div key={skill} className="p-2 sm:w-1/2 w-full">
                            <div className="bg-gray-800 rounded flex p-4 h-full items-center">
                                <BadgeCheckIcon className="text-green-400 w-6 h-6 flex-shrink-0 mr-4" />
                                <span className="title-font font-medium text-white">
                  {skill}
                </span>
                            </div>
                        </div>*/
                        <div
                            className={skill.className}>{skill.name}
                        </div>
                    ))}
                </div>
                {/*<div className=" mt-10 flex flex-row justify-center items-start flex-wrap min-w-full">
                    <div
                        className="rounded-full p-2 m-1 bg-gray-600 text-gray-100  w-24 h-24 flex items-center justify-center text-xl font-bold">Good
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-indigo-600 text-indigo-100  w-40 h-40 flex items-center justify-center text-xl font-bold">Analysis
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-pink-600 text-pink-100  w-32 h-32 flex items-center justify-center text-xl font-bold">Marketing
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-purple-600 text-purple-100  w-40 h-40 flex items-center justify-center text-xl font-bold">Branding
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-green-600 text-green-100  w-24 h-24 flex items-center justify-center text-xl font-bold">Growth
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-blue-600 text-blue-100  w-40 h-40 flex items-center justify-center text-xl font-bold">Reports
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-orange-600 text-orange-100  w-32 h-32 flex items-center justify-center text-xl font-bold">Metrics
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-yellow-600 text-yellow-100  w-40 h-40 flex items-center justify-center text-xl font-bold">Management
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-red-600 text-red-100  w-24 h-24 flex items-center justify-center text-xl font-bold">Fun
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-teal-600 text-teal-100  w-40 h-40 flex items-center justify-center text-xl font-bold">Profitable
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-gray-600 text-gray-100  w-32 h-32 flex items-center justify-center text-xl font-bold">Simple
                    </div>
                    <div
                        className="rounded-full p-2 m-1 bg-indigo-600 text-indigo-100  w-40 h-40 flex items-center justify-center text-xl font-bold">Reliable
                    </div>
                </div>*/}
            </div>
        </section>
    );
}
