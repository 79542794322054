
import React, { useEffect } from 'react';
import Intro from "./Intro"
import About from "./About";
import Contact from "./Contact";
import Navbar from "./NavBar";
import Projects from "./Projects";
import Skills from "./Skills";
import Experience from "./Experience";
import Certification from "./Certification";
import Leadership from "./Leadership";
import Footer from "./Footer.js";


function Home() {
    return (


        <main className="text-gray-400 bg-gray-900 body-font sc">
            <Navbar />
            <Intro />
            <About />
            <Skills />
            <Experience />
            <Projects />
            <Certification />
            <Leadership />
            <Contact />
            <Footer />
        </main>

    );
}



export default Home
