import React from "react";
import {ClipboardCheckIcon} from "@heroicons/react/outline";

export default function Certification() {

    return (
        <section id="certification">
            <div className="flex flex-col bg-gray-900 m-auto p-auto">
                <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                    <div className="flex flex-col w-full mb-20">
                        <ClipboardCheckIcon className="mx-auto inline-block w-10 mb-4" />
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                            Certifications and Awards
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">

                        </p>
                    </div>

                    <div
                        className="flex scrollbar-thin scrollbar-thumb-green-500 scrollbar-track-gray-700 pb-10"
                    >
                        <div
                            className="flex flex-nowrap"
                        >
                            <div className="inline-block px-3">
                                <div
                                    className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                                >
                                    <img

                                        className=" w-96 h-48 p-4"
                                        src="./cert_1.png"
                                    />
                                </div>
                            </div>
                            <div className="inline-block px-3">
                                <div
                                    className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                                >
                                    <img

                                        className=" w-96 h-48 p-4"
                                        src="./cert_2.png"
                                    />
                                </div>
                            </div>
                            <div className="inline-block px-3">
                                <div
                                    className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                                >
                                    <img

                                        className=" w-64 h-64"
                                        src="./cert_3.jpeg"
                                    />
                                </div>
                            </div>
                            <div className="inline-block px-3">
                                <div
                                    className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                                >
                                    <img

                                        className=" w-96 h-72"
                                        src="./cert_5.png"
                                    />
                                </div>
                            </div>
                            <div className="inline-block px-3">
                                <div
                                    className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                                >
                                    <img

                                        className=" w-96 h-72"
                                        src="./cert_4.jpeg"
                                    />
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}