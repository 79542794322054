import React, {Component, Fragment} from 'react';
import {
    BrowserRouter,
    Routes,
    Route} from 'react-router-dom'
import Welcome from './components/Home';
import withSplashScreen from './components/splashscreen';

class App extends Component {
    render() {
        return (
            <Fragment>

                <div className="container-fluid">
                <BrowserRouter>
                    <Routes>

                            <Route path="/" exact component={Welcome} />

                    </Routes>
                </BrowserRouter>
                </div>
            </Fragment>
        );
    }
}

export default withSplashScreen(App);