export const projects = [
    {
        title: "Gadget Master",
        subtitle: "Ecommerce site",
        description:
            "Built with Spring MVC",
        image: "./project-1.gif",
        link: "https://github.com/nikhilbharath/E-Commerce-Site",
    },
    {
        title: "Micro Blogger Service",
        subtitle: "Data Pipeline with Twitter API",
        description:
            "Twitter microblogging service clone built with NodeJS",
        image: "./project-2.gif",
        link: "https://github.com/nikhilbharath/TwitterClone_Microblogger.git",
    },
    {
        title: "Charitable Trust",
        subtitle: "NGO In-House Project built with Basic Javascript",
        description:
            "A tax exemption form using Javascript for donations and rituals",
        image: "./project-4.gif",
        link: "https://github.com/nikhilbharath/NGOproject_Ghosam.git",
    },
    {
        title: "Video content delivery",
        subtitle: "Optimization of Video request routing",
        description:
            "Video request routing with Djitkras algorithm and local cache using JavaFx",
        image: "./project-3.gif",
        link: "https://github.com/nikhilbharath/Video_Request_Routing.git",
    },
    {
        title: "Arduino Project",
        subtitle: "Energy Conservation using PIR sensor",
        description:
            "Proof of content proving Electrical power conservation in residential buildings with human temperature detection",
        image: "./project-5.gif",
        link: "https://github.com/nikhilbharath/ArduinoPIR-miniproject.git",
    }
];

export const skills = [ "Javascript", "React", "Java", "Python3", "Oracle", "C", "SQL"];

export const skill = [
    {
        className: "rounded-full p-2 m-1 bg-gray-600 text-gray-100  w-24 h-24 flex items-center justify-center text-xl font-bold",
        name: "C"
    },
    {
        className: "rounded-full p-2 m-1 bg-blue-600 text-blue-100  w-40 h-40 flex items-center justify-center text-xl font-bold",
        name: "Java"
    },
    {
        className: "rounded-full p-2 m-1 bg-pink-600 text-pink-100  w-32 h-32 flex items-center justify-center text-xl font-bold",
        name: "React"
    },
    {
        className: "rounded-full p-2 m-1 bg-purple-600 text-blue-100  w-40 h-40 flex items-center justify-center text-xl font-bold",
        name: "Python"
    },
    {
        className: "rounded-full p-2 m-1 bg-green-600 text-green-100  w-24 h-24 flex items-center justify-center text-xl font-bold",
        name: "DevOps"
    },
    {
        className: "rounded-full p-2 m-1 bg-blue-900 text-blue-100  w-40 h-40 flex items-center justify-center text-xl font-bold",
        name: "Javascript"
    },
    {
        className: "rounded-full p-2 m-1 bg-red-400 text-red-100 w-32 h-32 flex items-center justify-center text-xl font-bold",
        name: "NoSQL"
    },
    {
        className: "rounded-full p-2 m-1 bg-yellow-600 text-blue-100  w-40 h-40 flex items-center justify-center text-xl font-bold",
        name: "Oracle SQL"
    },
    {
        className: "rounded-full p-2 m-1 bg-red-600 text-red-100  w-24 h-24 flex items-center justify-center text-xl font-bold",
        name: "C++"
    },
    {
        className: "rounded-full p-2 m-1 bg-green-900 text-green-100  w-40 h-40 flex items-center justify-center text-xl font-bold",
        name: "Red Hat Linux"
    },
    {
        className: "rounded-full p-2 m-1 bg-gray-600 text-gray-100  w-24 h-24 flex items-center justify-center text-md font-bold",
        name: "Kubernetes"
    },

    {
        className: "rounded-full p-2 m-1 bg-indigo-600 text-indigo-100  w-40 h-40 flex items-center justify-center text-xl font-bold",
        name: "AWS / GCP"
    },
    {
        className: "rounded-full p-2 m-1 bg-gray-600 text-gray-100  w-32 h-32 flex items-center justify-center text-xl font-bold",
        name: "REST "
    },
    {
        className: "rounded-full p-2 m-1 bg-purple-600 text-gray-50  w-28 h-28 flex items-center justify-center text-xl font-bold",
        name: "Docker"
    }

    ];

export const experience = [
    {
        title: "Forecast tool",
        subtitle: "Global Health Impact Project",
        description:
            "To predict future health impact based on diseases, drugs, company and country",
        image: "./exp_1.png",

    },
    {
        title: "Supply Chain Logistics",
        subtitle: "Royal Enfield",
        description:
            "Complete knockdown and shipping of product parts for export models",
        image: "./exp_3.jpeg",

    },
    {
        title: "PTC Windchill - SAP Data Pipeline",
        subtitle: "Royal Enfield",
        description:
            "SAP Material Master data flow through PLM using BAPI",
        image: "./exp_4.png",

    },
    {
        title: "Web Statistics",
        subtitle: "Global Health Impact Project",
        description:
            "Data Modeling and Visualization on the global health impact score ",
        image: "./exp_5.png",

    },
    {
        title: "Cost Governance",
        subtitle: "Royal Enfield",
        description:
            "Cost governance tool for the product based on the CAD drawings",
        image: "./exp_2.jpeg",

    },
    {
        title: "FACTRE - IoT Project",
        subtitle: "Royal Enfield",
        description:
            "Purchase order dataflow from DMS through FACTRE to ERP system to achieve VIN engraving",
        image: "./exp_6.png",

    }
];