import React from "react";

import "../chatbot.css"

export default function Intro() {

    return (
        <section id="intro">

            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <div className="box">
                    <div id="chatbot">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                    <div id="chatbot-corner"></div>
                    <div id="antenna">
                        <div id="beam"></div>
                        <div id="beam-pulsar"></div>
                    </div>
                </div>


                    <h3 className="title-font sm:text-base text-base mb-4 font-medium text-white">
                        Have you met
                    </h3>
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                        Nikhil Ramaswamy ?
                    </h1>
                    <h2 className="title-font sm:text-2xl text-3xl mb-4 font-medium text-white">
                        <br className="hidden lg:inline-block" />He's a Software Engineer and &nbsp;
                        <br className="hidden lg:inline-block" />he would love to build apps for you
                    </h2>
                    <h4 className="title-font sm:text-2xl text-3xl mb-4 font-medium text-white">

                    </h4>
                    <div className="flex justify-center">

                    </div>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img
                        className="object-cover object-center rounded"
                        alt="hero"
                        src="./Intro.png"
                    />
                </div>
            </div>
            <br /><br />
        </section>
    );
}