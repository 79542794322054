import React from "react";
import '../App.css';
import {BriefcaseIcon, UserIcon} from "@heroicons/react/solid";
import {experience} from "../data";

export default function Experience() {

    return (
        // <section id="experience">
        //
        //         <div className="flex flex-col bg-gray-900 m-auto p-auto">
        //             <div className="container px-10 py-10 mx-auto text-center lg:px-30">

        //
        //                 <div className="container mx-auto mt-10">
        //                     <div id="summary" className="w-1/4 px-8 py-10">
        //                         <h1 className="font-semibold text-2xl border-b pb-8">Order Summary</h1>
        //                         <div className="flex justify-between mt-10 mb-5">
        //                             <span className="font-semibold text-sm uppercase">Items 3</span>
        //                             <span className="font-semibold text-sm">590$</span>
        //                         </div>
        //                         <div>
        //                             <label
        //                                 className="font-medium inline-block mb-3 text-sm uppercase">Shipping</label>
        //                             <select className="block p-2 text-gray-600 w-full text-sm">
        //                                 <option>Standard shipping - $10.00</option>
        //                             </select>
        //                         </div>
        //                         <div className="py-10">
        //                             <label htmlFor="promo"
        //                                    className="font-semibold inline-block mb-3 text-sm uppercase">Promo
        //                                 Code</label>
        //                             <input type="text" id="promo" placeholder="Enter your code"
        //                                    className="p-2 text-sm w-full" />
        //                         </div>
        //                         <button
        //                             className="bg-red-500 hover:bg-red-600 px-5 py-2 text-sm text-white uppercase">Apply
        //                         </button>
        //                         <div className="border-t mt-8">
        //                             <div className="flex font-semibold justify-between py-6 text-sm uppercase">
        //                                 <span>Total cost</span>
        //                                 <span>$600</span>
        //                             </div>
        //                             <button
        //                                 className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">Checkout
        //                             </button>
        //                         </div>
        //                     </div>
        //
        //                         <div className="w-3/4 bg-white px-10 py-10">
        //                             <div className="flex right border-b pb-8">
        //                                 <h1 className="font-semibold text-2xl">Professional Projects</h1>
        //                                 <h2 className="font-semibold text-2xl"></h2>
        //                             </div>
        //                             <div
        //                                 className="flex overflow-y-auto pb-10 hide-scroll-bar"
        //                             >
        //                                 <div
        //                                     className="flex flex-nowrap lg:ml-40 md:ml-20 ml-10 "
        //                                 >
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                     <div className="inline-block px-3">
        //                                         <div
        //                                             className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
        //                                         ></div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //
        //                         </div>
        //
        //
        //
        //
        //                 </div>
        //             </div>
        //         </div>
        // </section>
    <section id="experience" className="text-gray-400 bg-gray-900 body-font">

            <div className="container px-5 py-10 mx-auto text-center">

                <BriefcaseIcon className="mx-auto inline-block w-10 mb-4" />
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                    Experience
                </h1>

            </div>
                <div className="container mx-auto flex px-10 py-10 md:flex-row flex-col items-center">
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">

                <div
                    className="p-1 mb-4 bg-gray-900 rounded-lg ">

                    <ol className="relative border-l border-gray-200 dark:border-gray-700">
                        <li className="mb-12 ml-8">
        <span
            className="flex absolute -left-5 justify-center items-center w-10 h-10 ">
            <img className=" rounded-full"
                 src="GHI logo.png"></img>
        </span>
                            <h3 className="flex items-center mb-1 text-left text-lg font-semibold text-gray-50 dark:text-white"> Technical Lead <span
                                    className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">Present</span>
                            </h3>
                            <h3 className="flex items-center mb-1 text-left text-sm font-semibold text-gray-50 dark:text-white"> Dec 2021
                            </h3>
                            <time
                                className="block mb-2 text-left text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Internship - Global Health Impact, USA
                            </time>


                        </li>
                        <li className="mb-12 ml-8">
        <span
            className="flex absolute -left-5 justify-center items-center w-10 h-10 ">
            <img className=" rounded-full"
                 src="GHI logo.png"></img>
        </span>
                            <h3 className="flex items-center mb-1 text-left text-lg font-semibold text-gray-50 dark:text-white"> Software Engineer </h3>
                            <h3 className="flex items-center mb-1 text-left text-sm font-semibold text-gray-50 dark:text-white"> Sep 2021 - Dec 2021 ( 4 mos )
                            </h3>
                            <time
                                className="block mb-2 text-left text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Internship - Global Health Impact, USA
                            </time>

                        </li>

                    </ol>



                    <ol className="relative border-l border-gray-200 dark:border-gray-700">
                        <li className="mb-12 ml-8">
        <span
            className="flex absolute -left-5 justify-center items-center w-10 h-10 ">
            <img className=" rounded-full"
                 src="RE logo.jpg"></img>
        </span>
                            <h3 className="flex items-center mb-1 text-left text-lg font-semibold text-gray-50 dark:text-white"> Assistant Manager
                            </h3>
                            <h3 className="flex items-center mb-1 text-left text-sm font-semibold text-gray-50 dark:text-white"> Jul 2018 - Nov 2020 ( 2 yrs 5 mos )
                            </h3>
                            <time
                                className="block mb-2 text-left text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Full Time - Royal Enfield (A Unit of Eicher Motors), India
                            </time>


                        </li>
                        <li className="mb-12 ml-8">
        <span
            className="flex absolute -left-5 justify-center items-center w-10 h-10 ">
            <img className=" rounded-full"
                 src="RE logo.jpg"></img>
        </span>
                            <h3 className="flex items-center mb-1 text-left text-lg font-semibold text-gray-50 dark:text-white"> Graduate Engineer </h3>
                            <h3 className="flex items-center mb-1 text-left text-sm font-semibold text-gray-50 dark:text-white"> Jul 2017 - Jun 2018 ( 1 yr )
                            </h3>
                            <time
                                className="block mb-2 text-left text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Full Time - Royal Enfield (A Unit of Eicher Motors), India
                            </time>

                        </li>

                    </ol>

                </div>

            </div>

            <div className="lg:flex-grow md:w-full lg:pr-1 md:pr-1 md:w-screen flex flex-col mx-auto">
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                    Notable Projects he has worked on
                </h1>
                <div className="container mx-auto">

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

                    {/*<div className="flex items-center">*/}

                    {/*        <div className="flex flex-col w-56 h-56 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">*/}
                    {/*            <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">Website Development</strong>*/}
                    {/*            <span className="text-slate-500 text-sm font-medium dark:text-slate-400">Global Health Impact</span>*/}
                    {/*        </div>*/}
                    {/*</div>*/}
                    {/*<div className="flex items-center">*/}

                    {/*        <div className="flex flex-col w-56 h-56 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">*/}
                    {/*            <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">Forcast tool</strong>*/}
                    {/*            <span className="text-slate-500 text-sm font-medium dark:text-slate-400">To predict the future impact score of diseases and drugs</span>*/}
                    {/*        </div>*/}
                    {/*</div>*/}
                    {/*<div className="flex items-center">*/}

                    {/*    <div className="flex flex-col w-56 h-56 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">*/}
                    {/*        <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">Website Development</strong>*/}
                    {/*        <span className="text-slate-500 text-sm font-medium dark:text-slate-400">Global Health Impact</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="flex items-center">*/}

                    {/*    <div className="flex flex-col w-56 h-56 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">*/}
                    {/*        <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">Forcast tool</strong>*/}
                    {/*        <span className="text-slate-500 text-sm font-medium dark:text-slate-400">To predict the future impact score of diseases and drugs</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="flex items-center">*/}

                    {/*    <div className="flex flex-col w-56 h-56 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">*/}
                    {/*        <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">Forcast tool</strong>*/}
                    {/*        <span className="text-slate-500 text-sm font-medium dark:text-slate-400">To predict the future impact score of diseases and drugs</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="flex items-center">*/}

                    {/*    <div className="flex flex-col w-56 h-56 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">*/}
                    {/*        <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">Forcast tool</strong>*/}
                    {/*        <span className="text-slate-500 text-sm font-medium dark:text-slate-400">To predict the future impact score of diseases and drugs</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {experience.map((project) => (
                        <a
                            key={project.image}
                            className="flex flex-col w-96 h-64 p-8 max-w-xs overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out">
                            <div className="flex relative">
                                <img
                                    alt="gallery"
                                    className="absolute inset-0 w-96 h-48 p-4 object-cover object-center"
                                    src={project.image}
                                />
                                <div className="px-4 py-6 relative z-2 w-max border-2 border-white bg-gray-900 opacity-0 hover:opacity-100">
                                    <h2 className="tracking-widest text-sm title-font font-small text-green-400 mb-1">
                                        {project.subtitle}
                                    </h2>
                                    <h1 className="title-font text-lg font-small text-white mb-3">
                                        {project.title}
                                    </h1>
                                    <p className="leading-relaxed text-sm title-font font-small text-white mb-1">{project.description}</p>
                                </div>
                            </div>
                        </a>
                    ))}

                </div>
                </div>

            </div>
        </div>
    </section>
    );
}