import React from "react";
import apiKey from "./emailkey";
import emailjs from 'emailjs-com';
import {UserIcon, BadgeCheckIcon} from "@heroicons/react/solid";
import classNames from "classnames";
import toast, { Toaster } from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";


import styles from "../App.css";

export default function Contact() {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");

    function encode(data) {
        return Object.keys(data)
            .map(
                (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
            )
            .join("&");
    }

    const notify = () =>
        toast.custom(
            (t) => (
                <div className="flex h-64 w-64">
                    <div className="m-auto absolute top-20 right-0">
                        <div className="bg-white rounded-lg border-gray-300 border p-3 shadow-lg top-0 right-0">
                            <div className="flex flex-row">
                                <div className="px-2">
                                    <svg width="24" height="24" viewBox="0 0 1792 1792" fill="#44C997"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1299 813l-422 422q-19 19-45 19t-45-19l-294-294q-19-19-19-45t19-45l102-102q19-19 45-19t45 19l147 147 275-275q19-19 45-19t45 19l102 102q19 19 19 45t-19 45zm141 83q0-148-73-273t-198-198-273-73-273 73-198 198-73 273 73 273 198 198 273 73 273-73 198-198 73-273zm224 0q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
                                    </svg>
                                </div>
                                <div className="ml-2 mr-6">
                                    <span className="font-semibold">Email Sent!</span>
                                    <span
                                        className="block text-gray-500">Thanks! Nikhil will get back to you with his availability</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
            { id: "unique-notification", position: "top-center" }
        );
    function Delay() {
        return 1;
    }

    function handleSubmit(e) {
        e.preventDefault(); // Prevents default refresh by the browser
        emailjs.sendForm("service_2aqt2w9", apiKey.TEMPLATE_ID, e.target, apiKey.USER_ID)
        /*new Promise((resolve, reject) => {
            resolve('Success!');
        })*/
            .then((result) => {
                    //alert(`Email Sent to Nikhil, He will get back to you ASAP. Thank you!`);
                    notify();

                },
                (error) => {
                    alert(`An error occurred, Please try again ${error.text}`);
                });
        /*e.preventDefault();
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", name, email, message }),
        })
            .then(() => alert(`Message sent, Mr ${name}`))
            .catch((error) => alert(error));*/
    }

    return (
        <section id="contact">
            <br /><br />
            <br /><br />

            <div className="container px-5 py-10 mx-auto text-center">
                <UserIcon className="mx-auto inline-block w-10 mb-4" />
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                    Contact
                </h1>

            </div>
            <div className="flex justify-center">
            {/*    <div className="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">*/}
            {/*        <iframe*/}
            {/*            width="100%"*/}
            {/*            height="100%"*/}
            {/*            title="map"*/}
            {/*            className="absolute inset-0"*/}
            {/*            frameBorder={0}*/}
            {/*            marginHeight={0}*/}
            {/*            marginWidth={0}*/}
            {/*            style={{ filter: "opacity(0.7)" }}*/}
            {/*            src="https://www.google.com/maps/embed/v1/place?q=130+main+st+Binghamton+&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"/>*/}
            {/*        <div className="bg-gray-900 relative flex flex-wrap py-4 rounded shadow-md">*/}
            {/*            <div className="lg:w-1/2.5 px-6">*/}
            {/*                <h2 className="title-font font-semibold text-white tracking-widest text-xs">*/}
            {/*                    ADDRESS*/}
            {/*                </h2>*/}
            {/*                <p className="mt-1">*/}
            {/*                    130 Main St. <br />*/}
            {/*                    New York, NY 13905*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">*/}
            {/*                <h2 className="title-font font-semibold text-white tracking-widest text-xs">*/}
            {/*                    EMAIL*/}
            {/*                </h2>*/}
            {/*                <a className="text-indigo-400 leading-relaxed">*/}
            {/*                    nbramaswamy03@gmail.com*/}
            {/*                </a>*/}
            {/*                <h2 className="title-font font-semibold text-white tracking-widest text-xs mt-4">*/}
            {/*                    PHONE*/}
            {/*                </h2>*/}
            {/*                <p className="leading-relaxed">747-474-5359</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
                <form
                    netlify
                    name="contact"
                    onSubmit={handleSubmit}
                    className="lg:w-1/3 sm:w-full flex flex-col">
                    <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
                        Let's Connect
                    </h2>
                    <p className="leading-relaxed mb-5">

                    </p>
                    <div className="relative mb-4">
                        <label htmlFor="name" className="leading-7 text-sm text-gray-400">
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="relative mb-4">
                        <label htmlFor="email" className="leading-7 text-sm text-gray-400">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="relative mb-4">
                        <label
                            htmlFor="message"
                            className="leading-7 text-sm text-gray-400">
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <button
                        type="submit"
                        className=" mx-auto inline-block flex justify-center items-center text-center text-white bg-green-500 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">                        Send Message
                    </button>
                    <br/> <br/>
                <Toaster></Toaster>
                </form>
            </div>
        </section>
    );
}