import React from "react";
import {UserIcon} from "@heroicons/react/solid";

export default function About() {

    return (
        <section id="about" className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-10 mx-auto text-center">
                <UserIcon className="mx-auto inline-block w-10 mb-4" />
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                    Get to know him :)
                </h1>

            </div>
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">

                <div className=" md:w-1/2 sm:w-full lg:pr-24 md:pr-16 lg:max-w-lg lg:w-full w-5/6 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <img
                        className="object-cover object-center rounded mx-auto"
                        alt="hero"
                        src="./code.svg"
                    />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">

                    <p className="mb-8 flex justify-center leading-relaxed">
                        As a Software Engineer, he has worked on business process automation in product concerned verticals. He is holding 40 months of full stack experience working with one of the leading automobile manufacturers.
                        Highly passionate about working on interesting projects. Machine Learning and Data Science are his leisure interests.
                    </p>

                </div>
            </div>
        </section>
    );
}