import React from "react";
import {UserIcon} from "@heroicons/react/solid";
import {UserGroupIcon} from "@heroicons/react/outline";

export default function Leadership() {

    return (
        <section id="leadership" className="container mx-auto">
            <br /><br /><br /><br /><br /><br />
           {/* <div className="container mx-auto flex px-5 py-0 ">
                <div className="grid grid-cols-2 divide-x"><div>
                <h3 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-white">
                    President<span
                    className="bg-blue-100 text-blue-800 text-base font-medium mr-3 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">Present</span>
                    <br className="hidden lg:inline-block" />
                </h3>
                <h4 className="title-font sm:text-xl text-sm mb-4 font-medium text-white">

                    <br className="hidden lg:inline-block" />Indian Graduate Student Organization, SUNY Binghamton
                </h4>
                <br />
                <h3 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-white">
                    Executive Board Member
                    <br className="hidden lg:inline-block" />
                </h3>
                <h4 className="title-font sm:text-xl text-sm mb-4 font-medium text-white">

                    <br className="hidden lg:inline-block" />Association of Information Technologists, SVCE
                </h4>
                <p className="mb-8 flex justify-center leading-relaxed">
                </p>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/8 w-5/6 ">

                    <div className="lg:flex-grow md:w-1/8 lg:pr-52 md:pr-52 flex flex-col  md:items-start md:text-left mb-10 md:mb-0 items-center text-center">
                        <div className="container px-5 py-10 mx-auto text-center">
                            <UserGroupIcon className="mx-auto inline-block w-10 mb-4" />
                            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                                Leadership
                            </h1>
                        </div>

                    </div>

                </div>
                </div>
            </div>*/}
            <div className="grid grid-cols-2 divide-x">
                <div>
                    <div className="container px-5 py-5 mx-auto text-center"><br /><br /><br /><br />
                        <UserGroupIcon className="mx-auto inline-block w-10 mb-4" />
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                            Leadership
                        </h1>
                    </div>
                </div>
                <div>
                    <div className="container px-5 py-10 pd-10 ml-0 text-left">
                    <h3 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-white ">
                        President<span
                        className="inline-block bg-blue-100 text-blue-800 text-base font-medium px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-2">Present</span>
                        <br className="hidden lg:inline-block" />
                    </h3>
                        <h3 className=" title-font sm:text-base text-sm mb-4 font-medium text-white">
                            August 2021
                            <br className="hidden lg:inline-block" />
                        </h3>
                    <h4 className="title-font sm:text-xl text-sm mb-4 font-medium text-white">

                        <br className="hidden lg:inline-block" />Indian Graduate Student Organization, SUNY Binghamton
                    </h4>
                    <br />
                    <h3 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-white">
                        Executive Board Member
                        <br className="hidden lg:inline-block" />
                    </h3>
                        <h3 className=" title-font sm:text-base text-sm mb-4 font-medium text-white">
                            June 2014 - May 2015
                            <br className="hidden lg:inline-block" />
                        </h3>
                    <h4 className="title-font sm:text-xl text-sm mb-4 font-medium text-white">

                        <br className="hidden lg:inline-block" />Association of Information Technologists, SVCE
                    </h4>
                    <p className="mb-8 flex justify-center leading-relaxed">
                    </p>
                </div>
                </div>
            </div>
            <div className="container mx-auto flex px-10 py-0 md:flex-row flex-col items-center">


                <div className="lg:flex-grow md:w-1/8 lg:pr-24 md:pr-16 md:w-1/8 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">

                </div>
            </div>
        </section>
    );
}